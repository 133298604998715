import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AccessDenied = () => {
  const history = useHistory()

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = import.meta.env.VITE_APP_PASSPORT_URL // Замените '/login' на нужный URL для редиректа
    }, 5000)

    return () => clearTimeout(timer) // Очистка таймера при размонтировании
  }, [history])

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Ошибка доступа</h1>
      <p>У вас нет доступа к системе. Вы будете перенаправлены через 5 секунд.</p>
    </div>
  )
}

export default AccessDenied