import React from 'react'
import { Route, Switch as RouterSwitch } from 'react-router-dom'
import { privateRoutes } from './routes/routes'
import { useAccessControl } from '../hooks/useAccessControl'
import AccessDenied from '../../features/AccessDenied/AccessDenied'
import PreLoader from '../components/PreLoader/PreLoader'

const PrivateRouter = () => {
    const hasAccess = useAccessControl()

    if (hasAccess === null) {
        return <PreLoader /> // Пока проверяется доступ
    }

    if (!hasAccess) {
        return <AccessDenied /> // Если доступа нет, показываем ошибку
    }

    return (
      <RouterSwitch>
          {privateRoutes.map((route) => (
            <Route
              component={route.component}
              path={route.path}
              exact={route.exact}
              key={route.path}
            />
          ))}
      </RouterSwitch>
    )
}

export default PrivateRouter
