import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getProjects } from '../api/auth'

export const useAccessControl = () => {
  const [hasAccess, setHasAccess] = useState<boolean | null>(null)
  const history = useHistory()

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await getProjects()
        const hasProMarketAccess = response.data.some(
          (project: { projectName: string }) => project.projectName === 'promarket'
        )
        setHasAccess(hasProMarketAccess)
      } catch (error) {
        setHasAccess(false) // Если запрос упал, считаем, что доступа нет
      }
    }

    checkAccess()
  }, [history])

  return hasAccess
}