import http from './config/instance'

export const getAccessToken = (token: string) =>
  http.httpCore.post('auth/login/external', {
    f3ProAccessToken: token,
  })

export const getUserClaims = () =>
  http.httpCore.get<{
    data: {
      items: {
        categoryId: string
        description: string
        ruName: string
        value: string
      }[]
    }
  }>('claim/user/current', {
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_GRPC_API_URL,
    params: {
      ProjectId: 4,
    },
  })

export type UserCurrentProjectsType = {
  projectId: number
  projectName: string
  description: string
  viewName: string
  rootCategoryId: string
}

// получение доступных проектов
export const getProjects = () =>
  http.httpCore.get<UserCurrentProjectsType[]>('project/current', {
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_PROFILE_API,
  })
