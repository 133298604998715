import React, { Suspense, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import RenderModal from '../../shared/components/modals/RenderModal'
import HeaderCustom from '../HeaderCustom/HeaderCustom'
import { useLocation } from 'react-router-dom'
import { getHomeRoute } from '../../shared/services/routeService'
import PopoverWarning from './PopoverWarning'
import DrawerCustom from '../DrawerCustom/DrawerCustom'
import { useActions } from '../../shared/hooks/useActions'
import { useAccessControl } from '../../shared/hooks/useAccessControl'

const SubHeaderPanel1 = React.lazy(
  () => import('../HeaderCustom/components/SubHeaderPanel')
)

interface LayoutProps {
  children?: React.ReactChild | React.ReactNode
}

export default function MainLayout({ children }: LayoutProps) {
  const location = useLocation()
  const isMainPage = location.pathname === getHomeRoute()

  const { getUserClaimsAsync } = useActions()
  const hasAccess = useAccessControl()

  ////////////////////////////////////////////////////////////////////
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  ////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getUserClaimsAsync()
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <RenderModal />
        {hasAccess && <HeaderCustom handleDrawerToggle={handleDrawerToggle} /> }
        {isMainPage && (
          <Suspense
            fallback={
              <p
                style={{
                  marginLeft: '16px',
                  fontSize: '600',
                  color: '#909DB5',
                }}
              >
                Загрузка...
              </p>
            }
          >
            {hasAccess &&  <DrawerCustom
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />}
          </Suspense>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            minHeight: '100vh',
            backgroundColor: isMainPage
              ? 'background.default'
              : 'background.paper',
          }}
        >
          <Toolbar />
          {isMainPage && hasAccess && (
            <Suspense
              fallback={
                <p
                  style={{
                    marginLeft: '16px',
                    fontSize: '600',
                    color: '#909DB5',
                  }}
                >
                  {' '}
                  Загрузка...
                </p>
              }
            >
              <SubHeaderPanel1 />
            </Suspense>
          )}
          {children}
          {hasAccess &&  <PopoverWarning isMainPage={isMainPage} />}
        </Box>
      </Box>
    </>
  )
}
